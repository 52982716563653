@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-ssp: 'CircularStd', 'Average Sans', 'Helvetica Neue', 'Source Sans Pro', 'PingFang SC', sans-serif;
  --container-pd: 10px;

  --page-width: 1520px;
  --section-width: 1120px;
  --app-height: 100vh;

  --font-primary-color: #252831;
  --font-secondary-color: #404040;
  --font-secondary-2-color: #6F7485;

  --color-ref-neutral: #E1E1E1;
  --color-ref-neutral1: #F3F5F7;
  --color-ref-neutral2: #F0F1F3;
  --color-ref-neutral3: #CDCDCD;
  --color-ref-neutral4: #999999;
  --color-ref-neutral5: #7B7B7B;

  --color-ref-primary: #485695;
  --color-ref-primary1: #edeef5;
  --color-ref-primary2: #8a94d9;
  --color-ref-primary3: #6974b6;
  --color-ref-primary4: #3c487b;

  --color-ref-orange: #E66B00;
  --color-ref-orange1: #FAEFE6;
  --color-ref-orange2: #C64B00;

  --color-ref-secondary: #FF8500;
  --color-ref-secondary1: #FFEBD6;
  --color-ref-secondary2: #FFB437;
  --color-ref-secondary3: #E58519;

  --color-ref-commercial: #0089FF;
  --color-ref-commercial-prominent: #0063AD;
  --color-ref-commercial-light: #E9F3FF;

  --color-ref-rural: #00C620;
  --color-ref-rural-prominent: #019C1A;
  --color-ref-rural-light: #EBF9EB;

  --color-ref-red: #c71720;
  --color-ref-purple: #9135A5;
  --color-ref-estimate: #44AC3D;
  --color-ref-green: #17A11A;

  --color-theme: var(--color-ref-primary);
  --color-theme-pressed: var(--color-ref-primary4);
  --color-theme-prominent: var(--color-ref-primary4);
  --color-theme-light: var(--color-ref-primary1);
  --color-theme-disabled: var(--color-ref-neutral3);

  --color-button-background: var(--color-theme);
  --color-button-background-pressed: var(--color-theme-pressed);
  --color-button-background-prominent: var(--color-theme-prominent);
  --color-button-background-disabled: var(--color-theme-disabled);
}

@media (min-width: 768px) {
  :root {
    --container-pd: 16px;
  }
}

@media (min-width: 1024px) {
  :root {
    --container-pd: 32px;
  }
}

@layer base {

  html,
  body {
    padding: 0;
    margin: 0;
  }

  article,
  footer,
  header,
  main,
  nav,
  section {
    display: block;
  }

  html {
    font-size: 100px;
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -webkit-overflow-scrolling: touch;
  }

  body {
    color: var(--font-primary-color);
    font-size: .16rem;
    line-height: .24rem;
    font-weight: 200;
    font-family: var(--font-ssp);
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  img {
    border-width: 0;
  }

  input[type=button],
  button {
    padding: 0;
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
  }


  select,
  input,
  textarea {
    outline: none;
    margin: 0;
    padding: 0;
    border-width: 0;
    outline: none;
    background-color: transparent;
  }

  input,
  button {
    font-weight: 200;
    font-family: var(--font-ssp);
    font-size: inherit;
    -webkit-appearance: none;
  }

  input:disabled,
  input:disabled::placeholder,
  textarea:disabled,
  textarea:disabled::placeholder {
    -webkit-text-fill-color: var(--color-ref-neutral4);
    opacity: 1;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 200;
  }

  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  * {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--color-ref-neutral);
  }
}

@layer components {
  .button-primary {
    @apply cursor-pointer w-auto px-12 h-40 bg-primary text-white text-sm rounded-sm inline-flex items-center justify-center align-middle transition-colors hover:bg-primary-pressed;
  }

  .button-primary:disabled {
    @apply bg-neutral-3 cursor-not-allowed;
  }

  .button-primary-out {
    @apply cursor-pointer w-auto px-12 h-40 bg-white text-primary text-sm rounded-sm inline-flex items-center justify-center align-middle border border-primary transition-colors hover:bg-primary-light;
  }

  .button-primary-out:disabled {
    @apply border-neutral-3 text-neutral-3 bg-white cursor-not-allowed;
  }

  .button-text-primary {
    @apply cursor-pointer text-primary hover:text-primary-pressed hover:drop-shadow-md;
  }

  .button-orange {
    @apply cursor-pointer w-auto px-12 h-40 bg-orange text-white text-sm rounded-sm inline-flex items-center justify-center align-middle transition-colors hover:bg-orange-pressed;
  }
  .button-orange:disabled {
    @apply bg-neutral-3 cursor-not-allowed;
  }

  .menu-btn {
    @apply relative flex justify-center items-center flex-col
           before:absolute before:w-full before:h-2 before:bg-font before:transition-transform before:top-0 before:rounded-full
           after:absolute after:w-full after:h-2 after:bg-font after:transition-transform after:bottom-0 after:rounded-full
           child-[i]:h-2 child-[i]:bg-font child-[i]:w-full child-[i]:rounded-full;
  }
  .menu-btn.open {
    @apply child-[i]:hidden before:top-auto before:w-4/5 before:rotate-45 after:bottom-auto after:w-4/5 after:-rotate-45;
  }

  @media screen(sm) {
    .display-on-mobile {
      @apply hidden;
    }
  }

  .rich-text {
    @apply space-y-24 childs-[a]:text-primary childs-[a]:underline childs-[*]:max-w-full;
  }
}

::before,
::after {
  --tw-content: '';
}

body.theme-commercial {
  --color-theme: var(--color-ref-commercial);
  --color-theme-pressed: var(--color-ref-commercial-prominent);
  --color-theme-prominent: var(--color-ref-commercial-prominent);
  --color-theme-light: var(--color-ref-commercial-light);
}

body.theme-rural {
  --color-theme: var(--color-ref-rural);
  --color-theme-pressed: var(--color-ref-rural-prominent);
  --color-theme-prominent: var(--color-ref-rural-prominent);
  --color-theme-light: var(--color-ref-rural-light);
}

/* article image caption */
.image-caption {
  @apply !mt-16 text-black text-sm pb-16 border-b;
}

[id^="hotjar-survey"] > div {
  font-size: 16px !important;
}
[id^="hotjar-survey"] button {
  font-size: 1em !important;
}
[id^="hotjar-survey"] button > span > svg {
  display: block;
}
